import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Link as ScrollLink } from 'react-scroll';

import Layout from '../components/GatsbyLayout';
import Helmet from '../components/Helmet';
import CaseStudyBanner from '../components/CaseStudyBanner';
import DotSubtitle from '../components/DotSubtitle';

const JoBList = [{
  title: 'DevOps Engineer',
  description: 'Cluj-Napoca, Romania | Remote',
  link: '/careers/devops-engineer/'
}, {
  title: 'React Native Engineer',
  description: 'Cluj-Napoca, Romania | Remote',
  link: '/careers/react-native/',
}, {
  title: 'Automation QA',
  description: 'Cluj-Napoca, Romania | Remote',
  link: '/careers/automation-qa/',
}, {
  title: 'Full Stack Javascript Engineer',
  description: 'Cluj-Napoca, Romania | Remote',
  link: '/careers/full-stack/',
}, {
  title: 'Senior Technical Architect',
  description: 'Cluj-Napoca, Romania | Remote',
  link: '/careers/architect/',
}, {
  title: 'Project Manager',
  description: 'Cluj-Napoca, Romania | Remote',
  link: '/careers/project-manager/',
}];

const renderJobDescription = (props) => {
  const { title, description, link } = props;

  return (
    <Link className="job" key={title} to={link}>
      <div className="job__description">
        <h4 tabIndex="0">{title}</h4>
        <div className="job__description--location">
          <div className="icon fa-map-marker" />
          <h5 className="details font-weight-normal" tabIndex="0">
            {description}
          </h5>
        </div>
      </div>
      <div className="job__details">
        <h5 className="job__details--text font-weight-light-bold">View Details</h5>
        <div className="job__details--icon icon fa-arrow-right" />
      </div>
    </Link>
  );
};

renderJobDescription.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
};

renderJobDescription.defaultProps = {
  title: '',
  description: '',
  link: '',
};

const renderHelmet = () => (
  <Helmet
    title="Open Careers - World Class Technical Leaders | MCRO"
    description="Join us on React, Angular, iOS, Android, NodeJS, PHP, QA Automation,
    DevOPS and Security Job openings and be part of a world-class software development team."
    keywords="Javascript, React.JS, Angular, Sencha, ExtJS, Vue.Js, Node.JS, PHP, iOS,
    Swift, Android, Java, DevOps, QA, Automation, Security"
    canonical
    canonicalPage="careers"
  />
);

const ViewOpenPositions = () => (
  <ScrollLink
    className="button-smooth"
    to="jobsList"
    smooth
    spy
    duration={500}
    activeClass="button-smooth_active"
    tabIndex="0"
    onKeyDown={(event) => { if (event.keyCode === 13) document.querySelector('.button-smooth').click(); }}
  >
    View open positions
  </ScrollLink>
);

const Careers = ({ location, data: gatsbyData }) => (
  <Layout location={location}>
    {renderHelmet()}
    <div id="careers" className="main careers main__inner">
      <CaseStudyBanner
        category="CAREERS"
        title="Join our team"
        description="We are a dinamically growing software development company with a reputation for quality and
          innovation. If you share our passion for engineering and excellence, MCRO could be the right place for you."
        customButton={<ViewOpenPositions />}
      />
      <h2 className="show-for-seo">
        If you are a top Engineer, Manager, UX Designer or QA and you want to work
        with other world class engineers this place is for you.
      </h2>

      <div className="gallery">
        <div className="gallery__image--1">
          <Img
            fluid={gatsbyData.career1.childImageSharp.fluid}
            className="gallery__image"
            alt="Group of people sitting next to a Christmas tree"
            title="Group of people sitting next to a Christmas tree"
          />
        </div>
        <div className="gallery__image--2">
          <Img
            fluid={gatsbyData.career2.childImageSharp.fluid}
            className="gallery__image"
            alt="Change by Design by Tim Brown book beside smartphone on a table"
            title="Change by Design by Tim Brown book beside smartphone on a table"
          />
        </div>
        <div className="gallery__image--3">
          <Img
            fluid={gatsbyData.career3.childImageSharp.fluid}
            className="gallery__image"
            alt="Turned on monitor and silver laptop on a white desk inside an office"
            title="Turned on monitor and silver laptop on a white desk inside an office"
          />
        </div>
        <div className="gallery__image--4">
          <Img
            fluid={gatsbyData.career4.childImageSharp.fluid}
            className="gallery__image"
            alt="Woman working on a laptop in an office with yellow walls"
            title="Woman working on a laptop in an office with yellow walls"
          />
        </div>
        <div className="gallery__image--5">
          <Img
            fluid={gatsbyData.career5.childImageSharp.fluid}
            className="gallery__image"
            alt="Closeup of a woman with headphones on working on a laptop"
            title="Closeup of a woman with headphones on working on a laptop"
          />
        </div>
      </div>

      <div id="jobsList" className="jobs-list">
        <DotSubtitle
          title="Job listings"
        />
        <div className="jobs-list--content">
          {JoBList.map(Job => renderJobDescription(Job))}
        </div>
      </div>
    </div>
  </Layout>
);

Careers.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

Careers.defaultProps = {
  data: null,
};

export default Careers;

export const query = graphql`
  query careersPage {
    career1: file(relativePath: { eq: "careers/1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    },
    career2: file(relativePath: { eq: "careers/2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    },
    career3: file(relativePath: { eq: "careers/3.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    },
    career4: file(relativePath: { eq: "careers/4.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    },
    career5: file(relativePath: { eq: "careers/5.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
